.mb-button {
    @extend .btn-sm;
    color: $charcoal-900;
    border-color: $gray;
    background-color: $surface-base !important;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.05) 100%);

    &:hover:not(.disabled):not(:disabled) {
        background-color: $surface-base;
        background: none;
        color: $charcoal-900;
        border-color: $gray;
    }

    &:active {
        background-color: $light-gray !important;
        color: $charcoal-900 !important;
        border-color: $gray !important;
    }

    &:disabled,
    &.disabled {

        &:hover {
            box-shadow: none;
        }
    }
}

.btn-primary {
    color: $white;

    &:hover,
    &:active {
        color: $white;
    }
}

.item-title {
    @include font-size(16px);
    font-weight: 500;
}

.customer-badge {
    display: inline-flex;
    align-items: center;
    background: $light-gray;
    padding: 0.4rem 0.5rem 0.3rem 0.5rem;

    .btn-close {
        @include font-size(8px);
    }
}

.accordion .accordion .accordion-button {
    padding: 0.5rem;
}

.notes-list .note-row:last-child {
    border: none !important;
}