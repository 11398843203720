.cursor-pointer {
	cursor: pointer;
}

.fw-300 {
	font-weight: 300 !important;
}

.fw-400 {
	font-weight: 400 !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-600 {
	font-weight: 600 !important;
}


.fw-700 {
	font-weight: 700 !important;
}

.fw-800 {
	font-weight: 800 !important;
}

.loader {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
    background: url('https://cdn.martinbros.com/content/mb-loader.gif') no-repeat center 100px
    rgba(255, 255, 255, 0.5) !important;
    background-size: 80px !important;

	&.loader-top {
		background-position: center 20px !important;
	}
}

.note-success-message {
	color: $green;
	opacity: 1;
	transition: all 250ms linear;
	max-height: 50px;

	&.success-hidden {
		opacity: 0 !important;
		max-height: 0 !important;
		transition: all 250ms linear 2s;
	}
}

.mb-group-item {
	&:hover {
		background-color: $charcoal-200;
	}
}