.mb-label-group,
.mb-inline-label-group {
	label {
		@extend .fw-600;
		margin: 0;

		&:after {
			display: inline-block;
			content: ":";
			letter-spacing: 0.5rem;
		}
	}
}

.mb-label-group {
	label {
		margin-bottom: 0.5rem;
		display: inline-block;
		white-space: nowrap;
	}
}

.mb-inline-label-group {
    display: flex;
	align-items: center;
	flex-wrap: wrap;

	label {
		margin-bottom: 0;
		white-space: nowrap;

		+ div,
		+ span,
		+ input,
		+ select,
		+ textarea {
			flex: 1;
			min-width: 0;
			@include media-breakpoint-down(sm) {
				margin-bottom: 0.5rem;
			}
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 0.5rem;
		}
	}

	@include media-breakpoint-down(sm) {
		display: block;
	}
}

.mb-label-data-row {
	margin-bottom: 0.15rem;

	&:last-child {
		margin-bottom: 0;
	}

	.mb-label-group,
	.mb-inline-label-group {
		margin-right: 0.5rem;
		display: inline-flex;

		label {
			margin-right: 0;
			margin-bottom: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		@include media-breakpoint-down(sm) {
			display: block;
			margin-bottom: 0.5rem;
		}
	}

	.mb-inline-label-group label ~ * {
		margin-bottom: 0;
	}
}