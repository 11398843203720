/* MB Variable Overrides */

// Color System

// Brand Colors
$mb-gold-1:         #FFEA00;
$mb-gold-2:         #FFD200;
$mb-gold-3:         #FBBF0E;
$mb-gold-4:         #CCBB00;
$black:             #000000;
$white:             #FFFFFF;

// Charcoal
$charcoal-50:       #F7FBFE;

$charcoal-100:      #F2F6F9;

$charcoal-200:      #ECF0F3;
$charcoal-300:      #CACFD2;
$charcoal-400:      #BCC0C2;
$charcoal-500:      #9DA1A3;
$charcoal-600:      #74777A;
$charcoal-700:      #606366;
$charcoal-750:      #414446;
$charcoal-800:      #2F3335;
$charcoal-900:      #202325;
$charcoal-925:      #1C1E1F;
$charcoal-950:      #191A1C;
$charcoal-975:      #161719;  // default ink
$charcoal-dark:     #080808;

$body-color:        $charcoal-900;


//Surface
$surface-light:     $white;
$surface-dark:      #F3F4F5;
$surface-darker:    #E6E6E6;
$surface-base:      #FAFAFA;
$light-gray:        #DFDFDF;
$gray:              #CCCCCC;

// Primary & Secondary Colors
$blue:                  #0d8aee;
$green:                 #4CAF50;
$yellow:                $mb-gold-2;
$orange:                #F57208;
$red:                   #D32F2F;
$purple:                #8a55a1;
$cyan:              #00bcd4;
$teal:              #47C1BF;
$magenta:           #C159A1;
$indigo:            #5C6AC4;

$secondary: $surface-base;

// Button Overrides
$btn-padding-y: .5rem;
$btn-padding-x: 1.1rem;

$btn-padding-y-sm: .35rem;
$btn-padding-x-sm: .7rem;

$btn-padding-y-lg: .69rem;
$btn-padding-x-lg: 3rem;

// Grid breakpoints
//
$grid-breakpoints:(
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
);

$container-max-widths:(
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1520px
);

$font-size-base: 0.875rem;
$headings-font-weight: 400;
$text-muted: $charcoal-500;

$accordion-button-bg: $white;
$accordion-button-active-bg: $surface-dark;