.bg-blue {
    background-color: $blue;
}

.bg-red {
    background-color: $red;
}

.text-charcoal-50 {
    color: $charcoal-50;
}