
/* BS overrides with MB styles / branding */
@import "style/bootstrap/custom.scss";

html, body, #root {
  min-height: 100vh;
}

.navbar.navbar-dark {
  a {
      color: $white;
      text-decoration: none;
  }

  .nav-link:hover {
      text-decoration: underline;
  }
}

.booth-card-link {
  color: initial;
  text-decoration: none;

  &:hover {
    color: initial;
  }

  .card-header {
      text-align: center;
      @include font-size(20px);
  }
}

.card-title {
  @include font-size(17px);
  
  a {
    text-decoration: none;
  }
}

.container-body {
  margin-top: 2rem;
  min-height: calc(100vh - 87px - 2rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.app-footer {
  margin-top: 3rem;
  text-align: center;
  background: $surface-darker;
  padding: 1rem;
  @include font-size(14px);

  a {
    color: initial;
  }
}

.presenter-logo-container {
  margin-bottom: 1rem;
  min-height: 75px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.presenter-contact-meta {
  @include media-breakpoint-up(sm) {
    display: flex;
    white-space: nowrap;
  }
}

.presenter-contact {
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.presenter-sep:after,
.presenter-contact-meta:after {
  content: "|";
  display: inline-block;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  color: $charcoal-500;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.presenter-sep:last-child:after {
  display: none;
}

.item-note-container .note-row:last-child {
  border: none !important
}

@include media-breakpoint-up(sm) {
  .flag-container {
    position: absolute;
    right: 15px;
    top: 5px;
  }
}